import React from 'react';
import PagiList from "../list/PagiList";
import Sort from "./DeviceListCompareFunctions";
import '!style-loader!css-loader!./DeviceList.css'; 
import {StatusField} from './CustomizeField';
import { For } from 'react-loops'
import Checkbox from '../device/Checkbox';
import Cookies from 'js-cookie';
import { mapType } from '../map/MapBrowser';

const columuns_desktop = [
  {dataField:'deviceId',  text:'ID',    sort:true, afunc: Sort.compareDeviceAsc,     dfunc: Sort.compareDeviceDesc }, 
  {dataField:'disp_name',      text:'Name',  sort:true, afunc: Sort.compareNameAsc,     dfunc: Sort.compareNameDesc },
  //{dataField:'group_name',     text:'Group', sort:true, afunc: Sort.compareGroupNameAsc,  dfunc: Sort.compareGroupNameDesc },
  //{dataField:'status',    text:'Status',sort:true, afunc: Sort.compareStatusAsc,  dfunc: Sort.compareStatusDesc, formatedField: StatusField },
  //{dataField:'geofence',  text:'Geo',   sort:true, afunc: Sort.compareStatusAsc,  dfunc: Sort.compareStatusDesc},
  {dataField:'show',      text:'Show',  sort:false},
]; 

const columuns_mobile = [
  {dataField:'deviceId',  text:'ID',    sort:true, afunc: Sort.compareDeviceAsc,     dfunc: Sort.compareDeviceDesc }, 
  {dataField:'disp_name',      text:'Name',  sort:true, afunc: Sort.compareNameAsc,     dfunc: Sort.compareNameDesc },
  //{dataField:'status',    text:'Status',sort:true, afunc: Sort.compareStatusAsc,  dfunc: Sort.compareStatusDesc, formatedField: StatusField },
  {dataField:'show',      text:'',  sort:false},
];

const SHOW_LABEL = 'show_label';
const SHOW_LINE = 'show_line';
const MEASURE_DISTANCE = 'measure_distance';
const MAX_LINE_LENGTH = 'max_line_length';
const MAP_TYPE = 'map_type';
const KNOT = 'knot';

var this_obj;

export default class DeviceList extends React.Component {
  constructor(props){
    super(props);
    this_obj = this;
    let w = this.getWindowSize();
    let columuns = columuns_desktop;
    let style = "device-list";

    if(w.width < 500){
      columuns = columuns_mobile;
      style = "device-list-mobile";
    }

    this.state = {
      is_show  : false,
      show_tab1: false,
      show_tab2: true,
      show_tab3: false,
      columuns : columuns,
      style    : style,
    }
    this.handleClick = this.handleClick.bind(this);
    this.handleLabelShow = this.handleLabelShow.bind(this);
    this.handleLineShow = this.handleLineShow.bind(this);
    this.handleMeasureDistance = this.handleMeasureDistance.bind(this);
    this.handleChangeMaxLength = this.handleChangeMaxLength.bind(this);
    this.handleChangeKnot = this.handleChangeKnot.bind(this);
    this.setMapType = this.setMapType.bind(this);
    this.handleTab1 = this.handleTab1.bind(this);
    this.handleTab2 = this.handleTab2.bind(this);
    this.handleTab3 = this.handleTab3.bind(this);
  }

  //cookieの値とstateの値が異なる場合、stateを更新
  componentDidMount() {
    //if (this.get_cookie_as_bool(SHOW_LABEL) !== this.props.show_label) { this.props.handleShowLabel(); }
    if (this.get_cookie_as_bool(SHOW_LINE) !== this.props.show_line) { this.props.handleShowLine(); }
    //if (this.get_cookie_as_bool(MEASURE_DISTANCE) !== this.props.measure_distance) { this.props.toggleMeasureDistance(); }
    let val = Cookies.get(MAX_LINE_LENGTH);
    if (val) { this.props.handleChangeMaxLength(val);} // max_line_lengthが設定されているかのみ確認
    let map_type = Cookies.get(MAP_TYPE);
    if (map_type) { this.props.setMapType(map_type);} // map_typeが設定されているかのみ確認
    if((this.get_cookie_as_bool(KNOT) !== undefined) && (this.get_cookie_as_bool(KNOT) !== this.props.knot)) { this.props.handleChangeKnot(); }
  }

  get_cookie_as_bool(key){
    let val = Cookies.get(key);
    if(!val) return val;
    switch(val){
      case 'true':
        return true;
      case 'false':
        return false;
    }
  }

  //ウインドウサイズの取得
  getWindowSize() {
    var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    w = w.innerWidth || e.clientWidth || g.clientWidth,
    h = w.innerHeight|| e.clientHeight|| g.clientHeight;

    return {
            width: w,
            height: h
    }
  }

  handleClick(){
    this.setState(prevState => ({
      is_show: !prevState.is_show
    }));
  }

  handleLabelShow(){
    //Cookies.set(SHOW_LABEL, !this.props.show_label);//show_labelはcoocikeに保存しない
    this.props.handleShowLabel();
  }

  handleLineShow(){
    Cookies.set(SHOW_LINE, !this.props.show_line);
    this.props.handleShowLine();
  }

  handleChangeKnot(){

    Cookies.set(KNOT, !this.props.knot);
    this.props.handleChangeKnot();
  }

  handleMeasureDistance(){
    //Cookies.set(MEASURE_DISTANCE, !this.props.measure_distance);
    this.props.handleMeasureDistance();
  }

  handleChangeMaxLength(e){
    Cookies.set(MAX_LINE_LENGTH, e.target.value);
    //親の値を更新
    this.props.handleChangeMaxLength(e.target.value);
  }

  setMapType(type){
    Cookies.set(MAP_TYPE, type);
    this.props.setMapType(type);
  }

  handleInputChange(id){
    //no groupの場合
    let newMap = []
    let deviceGroups = this_obj.props.deviceGroups;

    for(let j in deviceGroups){
      if(deviceGroups[j].id == id){
        //符号を反転
        deviceGroups[j].visible = !deviceGroups[j].visible
      }

      //新しいデバイスリストを生成
      if(deviceGroups[j].visible){
          for(let i in this_obj.props.originalMap){
            if(this_obj.props.originalMap[i].group_id == deviceGroups[j].id){
              newMap.push(this_obj.props.originalMap[i])
            }
            //No group
            if((!this_obj.props.originalMap[i].group_id) && (deviceGroups[j].id == -1))
              newMap.push(this_obj.props.originalMap[i])
          }
      }
    }

    //１つもグループが選択されない場合、ダミーのデバイスを準備する
    if(newMap.length == 0){
      const dummy = {id:0, id:0, name:"", pos:[0,0], }
      newMap.push(dummy);
    }


    //親コンポーネント関数呼び出し
    this_obj.props.handleUpdateDeviceMap( deviceGroups, newMap )
  }


  handleTab1(){
    this.setState(prevState => ({
      show_tab1: true,
      show_tab2: false,
      show_tab3: false,
    }));
  }

  handleTab2(){
    this.setState(prevState => ({
      show_tab1: false,
      show_tab2: true,
      show_tab3: false,
    }));
  }

  handleTab3(){
    this.setState(prevState => ({
      show_tab1: false,
      show_tab2: false,
      show_tab3: true,
    }));
  }


  render(){
    const {deviceMap} = this.props;
    const show_tab1 = this.state.show_tab1;
    const show_tab2 = this.state.show_tab2;
    const show_tab3 = this.state.show_tab3;

    if (deviceMap.length == 0)
      return "";


    return (
      <span className={this.state.style}>
        {!this.state.is_show && 
          <div className={"device-list-button"} onClick={this.handleClick}>
            <i className={"fas fa-plus fa-2x toggle-button"}></i>
          </div>
        }

        <div className={!this.state.is_show ? "device-list-contents display-none" : "device-list-contents"}>

          <input id="tab1" type="radio" name="tab_btn" defaultChecked={show_tab1} onClick={this.handleTab1}/>
          <input id="tab2" type="radio" name="tab_btn" defaultChecked={show_tab2} onClick={this.handleTab2}/>
          <input id="tab3" type="radio" name="tab_btn" defaultChecked={show_tab3} onClick={this.handleTab3}/>
          <input id="close" type="radio" onClick={this.handleClick}/>

          <div className={"tab_area"}>
            <label className={"tab1_label"} htmlFor={"tab1"}><i className={"fas fa-search fa-2x"}></i></label>
            <label className={"tab2_label"} htmlFor={"tab2"}><i className={"fas fa-cog fa-2x"}></i></label>
            <label className={"tab3_label"} htmlFor={"tab3"}><i className={"fas  fa-filter fa-2x"}></i></label>
            <label className={"close_label"} htmlFor={"close"}><i className={"fas fa-minus fa-2x"}></i></label>
          </div>

          <div className={"panel_area"}>
            <div id="panel1" className={"tab_panel"}>
              <PagiList
                      columuns={ this.state.columuns }
                      data={ deviceMap }
                      itemsPerPage={10}
                      options={{
                            searchBox: true,
                            multipleSelect: false,
                            expandable: false,
                            actionsColumn: false,
                            pagination: true,
                      }}
                      customizeField1 = { StatusField }
                      onSort={this.onSort}
                    />
            </div>

            <div id="panel2" className={"tab_panel"}>
              <div className={'setting_checkbox'}>
                <p>SHOW LABEL</p>
                <div className={"switch"}>
                  <label className={"switch__label"}>
                    <input type="checkbox" className={"switch__input"} checked={this.props.show_label} onChange={this.handleLabelShow}/>
                    <span className={"switch__content"}></span>
                    <span className={"switch__circle"}></span>
                  </label>
                </div>
              </div>

              <div className={'setting_checkbox'}>
                <p>MEASURE DISTANCE</p>
                <div className={"switch"}>
                  <label className={"switch__label"}>
                    <input type="checkbox" className={"switch__input"}  onChange={this.handleMeasureDistance}/>
                    <span className={"switch__content"}></span>
                    <span className={"switch__circle"}></span>
                  </label>
                </div>
              </div>

              <div className={'setting_checkbox'}>
                <p>SHOW LINE</p>
                <div className={"switch"}>
                  <label className={"switch__label"}>
                    <input type="checkbox" className={"switch__input"} checked={this.props.show_line} onChange={this.handleLineShow}/>
                    <span className={"switch__content"}></span>
                    <span className={"switch__circle"}></span>
                  </label>
                </div>
                <p>LINEの長さ（秒）</p>
                <div className={"switch"}>
                    <input type="number" className={"max_line_length"} value={this.props.max_line_length} onChange={this.handleChangeMaxLength} />
                </div>
              </div>

              <div className={'setting_checkbox'}>
                <p>KNOT表示</p>
                <div className={"switch"}>
                  <label className={"switch__label"}>
                    <input type="checkbox" className={"switch__input"}  checked={this.props.knot} onChange={this.handleChangeKnot}/>
                    <span className={"switch__content"}></span>
                    <span className={"switch__circle"}></span>
                  </label>
                </div>
              </div>


              <div className={'setting_checkbox'}>
              <p>地図の種類</p>
              <button onClick={() => {this.setMapType(mapType.bing)}}>{mapType.bing}</button>
              <button onClick={() => {this.setMapType(mapType.osmBlackAndWhite)}}>{mapType.osmBlackAndWhite}</button>
              <button onClick={() => {this.setMapType(mapType.osmMapnik)}}>{mapType.osmMapnik}</button>
              <button onClick={() => {this.setMapType(mapType.gsi)}}>{mapType.gsi}</button>
              </div>

            </div>

            <div id="panel3" className={"tab_panel"}>
              <p>表示するグループをチェックしてください</p>
                <For of={this.props.deviceGroups} ifEmpty={<div>no device groups.</div>}>
                  {item => <div><Checkbox label={item.group_name + "  (" + item.count + ")"}
                                          ischecked={item.visible} handleCheckboxChange={() => this.handleInputChange(item.id)} /></div>}
                </For>
            </div>

          </div>

        </div>
      </span>
    );
  }
}
