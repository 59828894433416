import React, { Component } from 'react';
import L from 'leaflet';
import { Marker, CircleMarker, Popup, Polyline, Tooltip } from 'react-leaflet';
import images from '../images';
import '!style-loader!css-loader!./MainPlayer.css'; 
import { Button } from 'react-bootstrap'

const mainColor = '#ff5100';
const subColor = '#a9ceec';


export default class MainPlayer extends Component {
  constructor(props){
    super(props);
    const { device } = this.props;

    //航跡ライン
    this.lines = [];
    this.all_lines = this.props.all_lines ? this.props.all_lines : []
    let color =  device.color_code ? device.color_code : '#ffffff';

    const markerHtmlStyles = `
      background-color: ${color};
      width: 1.0rem;
      height: 1.0rem;
      display: block;
      left: -0.5rem;
      top: 1.3rem;
      position: relative;
      border-radius: 3rem 3rem 0;
      transform: rotate(45deg);
      border: 0px solid #FFFFFF`

		let img;
		switch(device.category){
			case 3:
				img = images.buoy
				break;
			case 4:
				img = images.boat
				break;
			case 5:
				img = images.wind
				break;
			case 6:
				img = images.drone
				break;
			case 7:
				img = images.car+'?color=' + color
				break;
			case 8:
				img = images.sup+'?color=' + color
				break;
			case 9:
				img = images.camera+'?color=' + color
				break;
		}

    this.state = {
      mstyle: markerHtmlStyles,
      color: color,
      img: img,
      show_label : false,
    }
  }


  UNSAFE_componentWillMount(){
    const { device } = this.props;
    const iconAnchor = device.category == 2 ? [0,24] : [5,5] ;//icon size 16X16
    const markerHtmlStyles = this.state.mstyle;
    let iconHTML = `<span style="${markerHtmlStyles}" />`

    if((3 <= device.category) && (device.category <=9)){
      iconHTML = '<object id="'+device.deviceId+'" data='+this.state.img+' data-tip data-for="Speed-tooltip-'+device.deviceId+'"></object>';
    }

    this.icon = new L.DivIcon({ 
              className: "my-custom-pin",
              iconAnchor: iconAnchor,
              labelAnchor: [-6, 0],
              popupAnchor: [0, -36],
              html: iconHTML,
              });
  }

  speed(spd){
		if( this.props.knot ){
			let s =Math.round((spd / 1.852) * 10) /  10
			return String(s) + " knot"
		}else{
			return String(spd) + " km/h"
		}
  }


  TooltipHTML(props){
    return (
      <span>
        <div>{props.name}</div>
        <div> 
          ID: <span>{props.deviceId}</span><br/>
          Name: <span>{props.disp_name}</span><br/>
          Speed: <span>{this.speed(props.speed)}</span><br/>
          Updated: <span>{this.timeFormatter(props.time)}</span>
        </div>
      </span>
    );
  }

  //時間の表示形式の調整、いまは使わないが
  timeFormatter(t){
    if(!t)
      return
    return t
  }


  render() {
    const { device } = this.props;
    const CircleM = <CircleMarker center={device.pos} radius={18} />;
    const EmergencyM = <CircleMarker color={"#FF0000"} center={device.pos} radius={19} />;

    if( (device.pos) && (Math.abs(device.pos[0]) > 0) ){
      // category:1のみ直近航跡を表示する
      if(device.category == 1 || device.category == 2){
        if(this.props.max_line_length > this.lines.length){
          this.lines.push(device.pos);
        }else{
          if(this.props.max_line_length > 0){
            while(this.lines.length > this.props.max_line_length-1){
              this.lines.shift();
            }
            this.lines.push(device.pos);
          }
        }
      }

    }

    //Emergencyを受診後、５秒後に消せる様にする
    let once = setInterval(() => {
      if(device.emergency){
        this.setState({
          emergency_open: false,
        });
        clearInterval(once);
      }
    } ,5000);

    //Emergency
    const openMarker = ref => {
      if ((device.emergency && ref) && this.state.emergency_open) {
        ref.leafletElement.openPopup()
      }
    }
    //Emergency
    const EmergencyPop =(
      <Popup className={"popup-emergency"} direction={'top'}  permanent={true} offset={[0, -5]} >
        <div>Emergency at:{new Date(device.emergency_signaled_at).toLocaleString('ja-JP',{ hour12: false })}</div>
        <div>{device.emergency_reason}</div>
      </Popup>
    )

    const tooltipHTML = this.TooltipHTML(device);

    let label_flg;
    if(this.props.show_label || this.state.show_label){
      label_flg = true;
    }


		if(device.category == 1){
			let iconAnchor = device.category == [5,5] ;//icon size 16X16
			const markerArrow = `
				display: inline-block;
				margin-top: -5px;
				border-top:    6px solid transparent;
				border-right:  6px solid transparent;
				border-left:   6px solid transparent;
				transform: rotate(${device.rotate}deg);
				border-bottom: 15px solid ${this.state.color};
				`
				let iconHTML = `<span style="${markerArrow}" />`
				this.icon = new L.DivIcon({ 
									className: "my-custom-pin",
									iconAnchor: iconAnchor,
									labelAnchor: [-6, 0],
									popupAnchor: [0, -36],
									html: iconHTML,
									});
		}

    return (
      <span>
        <Marker
          position={device.pos}
          icon={this.icon}
          eventHandlers={{
            click: (e) => {
							if(this.props.measure_distance){
								this.props.handleShowDistanceDevices(this.props.device);
							} else {
								this.setState(state => ({
									isClicked: !state.isClicked
								}));
							}
							//ShowLabel disableの場合
							if(!this.props.show_label){
								this.setState({
									show_label: !this.state.show_label,
									isClicked: false,
								});
							}
            },}}
        >

          {device.emergency && EmergencyPop}

          { label_flg &&(
          <Tooltip className={"tooltip-player"} permanent={true} direction="right" offset={[5, 2]} interactive={true}>
            <div>{!this.state.isClicked? device.name : tooltipHTML}</div>
          </Tooltip>)}
        </Marker>

        {this.props.show_line && (
          <Polyline key={Math.random()} positions={this.lines} color={this.state.color} />
        )}

        {this.props.show_line && (
          <Polyline key={Math.random()} positions={this.all_lines} color={this.state.color} opacity={0.4} />
        )}

      {device.ischecked && CircleM}
      {device.emergency && EmergencyM}
     </span>
    );
  }
}
