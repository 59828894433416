import React from "react"

var websocket;
let updWebsocketTime = null;
var intervalWSCheck = null;
var intervalReconnect = null;

var init_count=0;
var err_count=0;

var isOnline = false;
var stop= false;
var g_this;
var g_devices;
var g_map_ws_endpoint;
var g_organization_id;
var g_authenticity_token;
var g_hidden_offline;
var g_delay = 6;//ディレイ初期値


export function cleanWebsocket(){
	clearInterval(intervalReconnect);
	clearInterval(intervalWSCheck);
	websocket.onopen = null;
	websocket.onclose = null;
	websocket.onmessage = null;
	websocket.close();
	console.log("ws closed.");
}

export function setDelay(liveDelay){
    g_delay = liveDelay
    if(isOnline){
      const message = JSON.stringify(g_delay); // JSON形式に変換
      websocket.send(message); // WebSocketで送信
    }
}

export function reInitializeWebsocket(delay){
  initializeWebsocket(g_devices, g_this, g_map_ws_endpoint, g_organization_id, g_authenticity_token, g_hidden_offline)
	g_delay = delay;
}

export function closeWebsocket(){
		stop = true
		clearInterval(intervalWSCheck);
		intervalWSCheck = null;
		websocket.onopen = null;
		websocket.onmessage = null;
    g_this?.setState({ online: false });
		websocket.close();
		console.log("ws closed.");
		isOnline = false;
	  init_count = init_count-1;
}

function setGlobalValiables(deviceMap, this_obj, map_ws_endpoint, organization_id, authenticity_token, hidden_offline){
  g_this = this_obj;
  g_devices = deviceMap;
  g_map_ws_endpoint = map_ws_endpoint;
  g_organization_id = organization_id;
  g_authenticity_token = authenticity_token;
  g_hidden_offline = hidden_offline;
}

export function initializeWebsocket(deviceMap, this_obj, map_ws_endpoint, organization_id, authenticity_token, hidden_offline){

  stop = false;
  setGlobalValiables(deviceMap, this_obj, map_ws_endpoint, organization_id, authenticity_token, hidden_offline);
	init_count = init_count+1;

	if(init_count > 1){
		console.log("initialize always running.:"+ init_count)
	}

	updWebsocketTime = new Date();
	let websocketURL = map_ws_endpoint + organization_id;
	console.log("[WS Endpoint] "+websocketURL)
	websocket = new WebSocket(websocketURL);

	//Websocketデータの受信をチェック
	let checkWebsocketOnline =() => {
    if(stop){
      return;
    }
		let now_t = new Date();
		console.log(now_t-updWebsocketTime)
		if( now_t-updWebsocketTime > 30000 ) {
			clearInterval(intervalWSCheck);
			intervalWSCheck = null;
			websocket.onopen = null;
			websocket.onmessage = null;
			websocket.close();
			console.log("ws closed.[time check]");
			init_count = init_count-1;
			this_obj.setState({
				online: false,
			});
		  isOnline = false;

			if(err_count < 3){
				err_count = err_count+1;
				console.log("Can not recieve data. Connection close. 再接続します。:"+err_count)
				initializeWebsocket(deviceMap, this_obj, map_ws_endpoint, organization_id, authenticity_token, hidden_offline);
			}
		}
	}


	websocket.onopen = (event) => {
		console.log("ws opened.");
		this_obj.setState({
			online: true,
		});
		isOnline = true;

		//Tokenを送信し、データ受信を行う
		websocket.send(authenticity_token)

		//送信時刻ディレイ
    if(g_delay >= 0){
      setDelay(g_delay)
    }

		//確立したら状態を確認する
		if( websocket.readyState != 1 ){
			this_obj.setState({
				online: false,
			});
		  isOnline = false;
			console.log("コネクションが切断(2)。3秒後に再接続します。:", websocket.readyState)
			intervalReconnect = setTimeout(() => {
        if(stop){
          return;
        }
				initializeWebsocket(deviceMap, this_obj, map_ws_endpoint, organization_id, authenticity_token, hidden_offline);
			}, 3000);
			return;
		}

		//WSのデータ受信状態をチェック
		if(!intervalWSCheck){//２周動作チェック
			intervalWSCheck = setInterval(() => {
				checkWebsocketOnline();
			}, 5000);
			return () => clearInterval(intervalWSCheck);
		}else{
			console.log("Check interval already running")
		}

	}


	websocket.onclose = (event) => {
    if(stop){
      return;
    }
		init_count = init_count-1;
		this_obj.setState({
			online: false,
		});
		isOnline = false;
		console.log("コネクションが切断(1)。3秒後に再接続します。")
		if(err_count < 3){
			err_count = err_count+1;
			intervalReconnect = setTimeout(() => {
				initializeWebsocket(deviceMap, this_obj, map_ws_endpoint, organization_id, authenticity_token, hidden_offline);
			}, 3000);
		}
	}

	websocket.onmessage = function (event) {
		err_count = 0;
		updWebsocketTime = new Date();//online確認用
		let data = JSON.parse(event.data);
		console.log(data);

		if(data.type != "gps")
			return

		let device;
		let deviceMap = this_obj.state.deviceMap;

		for(let i in deviceMap){
			if(deviceMap[i].deviceId == data.id){
				//デバイスのプロパティをアップデート
				device = deviceMap[i]
				break;
			}
		}

		if (device != null){
			//デバイスを移動
			let d = new Date();
			let t = data.time;
			d.setHours(t.slice(0, 2));
			d.setMinutes(t.slice(2, 4));
			d.setSeconds(t.slice(4, 9));
			d.setTime(d.getTime() + 1000*60*60*9);//9時間追加している？！　他にやり方があるので要修正

			device.emergency_signaled_at = data.emergency_signaled_at;
			device.emergency_reason = data.emergency_reason;
			device.emergency = data.emergency;

			//オフラインと判断される場合かつ、フラグがある場合は、即座に画面から消す
			if (data.emergency_reason.includes("NOSIGNAL") && hidden_offline) {
				device.ws_move(0, 0, 0, d.toLocaleTimeString());
			}
			//GPSを受信しているときのみ動かす
			else if(data.lat > 0.0){
				device.ws_move(data.lat, data.long, data.spd, d.toLocaleTimeString());
			}
		}
	}
}


