import React from 'react';
import Checkbox from './Checkbox';

export default class Device {

  constructor (id, d){
    this.deviceId = d.deviceId// アイコンのスタイル変更で使用予定
    this.id = id
    this.key = d.deviceId;
    this.pos = [0,0];
    this.speed = 0
    this.name = d.name
    this.category = d.category
    this.nation =  d.nation? " ("+d.nation+")" : ''
    this.sail_number = d.sail_number
    this.firstname = d.firstname
    this.lastname = d.lastname
		this.disp_name = d.category==1 ?
										  ( d.sail_number ?  d.sail_number +"/" + d.firstname + " " + d.lastname
										    : ( d.firstname ? d.firstname + " " + d.lastname : d.name ))
										 : d.name
    this.club = d.club
    this.rotate = 0;
    this.ischecked = false;
    this.group_id = d.group_id;
    this.group_name = d.group_name;
    this.color_code = d.color_code;
    this.status = "";
    this.geofence = [];
    this.ori_searchstr = d.deviceId + d.name + d.sail_number + d.firstname + d.lastname;
    this.searchstr = this.ori_searchstr;
    this.show = <Checkbox ischecked={this.ischecked} handleCheckboxChange={() => this.handleInputChange(this)} />
    this.emergency =   false;
    this.emergency_type = 0;
    this.emergency_signaled_at = null;
    this.emergency_reason = null;
    this.time = null;
    this.visible = true;
    this.max_length = 30;
    this.last_gps = null;

    if(d.gpses){
      this.gpses = d.gpses.map(function(g) {
        return new GPS(g.lat, g.lon, g.speed, g.time)
      });

      this.all_lines = d.gpses.map(function(g) {
        return [g.lat, g.lon]
      });
    }
    else{
      this.gpses = []
      this.all_lines = []
    }

    this.resetMovementData = function(){
      this.last_gps = null;
      this.all_lines = [];
      this.gpses = []
    }


    var type;
    switch(this.category){
      case 3:
        //this.icon = createIconFromImage(images.buoy)
        type = "buoy"
        break;
      case 4:
        //this.icon = createIconFromImage(images.boat)
        type = "boat"
        break;
      default:
        type = "athlete"
        if(!d.athlete_id)//athleteの設定がない
          this.name = d.name
        else if((d.firstname == undefined) && (d.name != ''))
          this.name = d.name;
        else if((d.firstname == undefined) && (d.name == ''))
          this.name = "No-Name";
        else{
          if(this.sail_number != '')
            this.name = this.sail_number
            //this.name = this.sail_number+"/"+this.lastname + this.nation
          else
            this.name = this.lastname +  this.nation
        }
        break;
    }

    this.max_time = function(){
      var max_time = new Date("Wed, 12 Sep 1000 00:38:03 GMT");
      var i
      for (i = 0; i < this.gpses.length; i++){
        let gps = this.gpses[i]
        if (gps.time > max_time){
          max_time = gps.time
        }
      }
      return max_time
    }

    this.min_time = function(){
      var min_time = new Date("Wed, 12 Sep 7000 00:38:03 GMT");
      var i
      for (i = 0; i < this.gpses.length; i++){
        let gps = this.gpses[i]
        if (gps.time < min_time){
          min_time = gps.time
        }
      }
      return min_time
    }

    this.ws_move = function(lat, lon, speed, time){
          this.rotate = Math.atan2((this.pos[0]-lat) , -(this.pos[1]-lon)) * ( 180 / Math.PI ) + 90
          this.pos = [lat, lon];
          this.speed = speed;
          this.time = time;
          this.last_gps = this.pos
    }

    this.move = function(time){
      this.searchstr = this.searchstr.replace("ONLINE", '');
      this.status = ""
			if((time-this.last_gps)/1000 > 180){
				this.rotate = 0;
				this.pos = [0,0];
				this.speed = 0
			}
      var i
      for (i=0; i < this.gpses.length - 1; i++){
        var gps = this.gpses[i]
        var next = this.gpses[i+1]
        if ( (gps.time <= time) && ((time <= next.time) && (next.time-time < 2000)) ){//next.timeが未来すぎる場合の調整2秒までをオンライン
          this.rotate = Math.atan2((this.pos[0]-gps.lat) , -(this.pos[1]-gps.lon)) * ( 180 / Math.PI ) + 90
          this.pos = [gps.lat,gps.lon];
          this.speed = gps.speed;
					this.last_gps = gps.time
          this.time = ('0' + gps.time.getHours()).slice(-2)
              + ':' + ('0' + gps.time.getMinutes()).slice(-2)
              + ':' + ('0' + gps.time.getSeconds()).slice(-2)
          this.status = ' '; // GPSデータが存在する場合statusを空白文字とし、DevicelistにONLINEと表示させる
          if ( !this.searchstr.match(/ONLINE/)) {
            this.searchstr += "ONLINE";
          }
        }
      }
			//速度があるときは、エマージェンシーを解除
			if(this.speed > 0){
				this.unset_alert();
			}

			if(this.last_gps && ((time-this.last_gps)/1000 > 60  && (time-this.last_gps)/1000 < 180)){
				//1分以上の更新がない場合、エマージェンシー発動
				this.emergency =   true;
				this.emergency_signaled_at = this.time;
				this.emergency_type=1;
				this.emergency_reason = "指定時間以上のNo-Signal.";
			}else{
				if(this.emergency_type==1)
					this.unset_alert();
			}

    }

    //CheckBox
    this.handleInputChange = this.handleInputChange.bind(this);
  }
	
	unset_alert(){
				this.emergency =   false;
				this.emergency_signaled_at = null;
				this.emergency_reason = null;
	}

  handleInputChange(event){
    this.ischecked = !this.ischecked;
    this.show = <Checkbox ischecked={this.ischecked} handleCheckboxChange={() => this.handleInputChange(this)} />
  }
}

class GPS{
  constructor(lat, lon, speed, time){
    this.lat = lat
    this.lon = lon
    this.speed = speed
    this.time = new Date(time)
  }
}
