import React, { useState, useEffect } from 'react';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Grid';
import { setDelay, closeWebsocket, reInitializeWebsocket } from './Websocket.js';
import { Button } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

var g_isPlaying = true

const default_delay = 6;

export default function DelayControl(props) {

  const { devices, setDevices } = props;
  const [dataRange, setDataRange] = useState({
    min: -4200,
    max: 0,
    step: 1,
  });
  const [timeOffset, setTimeOffset] = useState( default_delay );//デフォルトで6秒前
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
				if(g_isPlaying)
					setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);


  // フォーマットする関数
  const formatTime = (date) => {
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };
  const formatTimeJST = (date) => {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  const [displayTime, setDisplayTime] = useState(formatTimeJST(new Date()));

  // スライダーの値が変わったときの処理
  const handleSliderChange = (event, newValue) => {
    setTimeOffset(-newValue);  // 新しい値に基づいてtimeOffsetを更新
    setDelay(-newValue);

		//デバイス航跡をリセット
		if(!devices)
			return
		let deviceMap = new Map();
		devices.forEach(function(device, i) {
			device.resetMovementData();
			deviceMap.set( device.serial, device);
		});
		setDevices(deviceMap);
  };

  // 経過時間または残り時間の計算
  const isAtRightEnd = timeOffset <= default_delay;
  const liveDotColor = isAtRightEnd ? 'red' : 'rgba(255, 0, 0, 0.5)';

  //Play or Stop
  const handlePlay = () => {
    setIsPlaying(true)
		g_isPlaying = true
		//現在時刻と、停止時の時刻の差分
		let delay = Math.floor((new Date() - currentTime.getTime() + timeOffset * 1000)/1000); 
		console.log("再開秒数(前):",delay)

    // 現在時刻を即座に更新
    setCurrentTime(new Date());

		setTimeOffset(delay)
		reInitializeWebsocket(delay);
  }

  useEffect(() => {
    const timeDifference = currentTime.getTime() - timeOffset * 1000;
    setDisplayTime(formatTimeJST(new Date(timeDifference)));
  }, [currentTime, timeOffset]);

  const handlePause = () => {
    setIsPlaying(false)
		g_isPlaying = false
		closeWebsocket();
  }

  const onClickLive = (e) => {
    handleSliderChange(e, -default_delay)
  }



  return (
    <Grid container> 
      <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#000000', textAlign: 'right', color: '#FFFFFF' }} >
        {displayTime } 
      </Grid>
      <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#000000', textAlign: 'right', color: '#FFFFFF' }} >
        { !isPlaying?  (
          <Button onClick={handlePlay} style={{ color: '#ffffff' }}>
            <PlayArrowIcon />
          </Button> ) : (
          <Button onClick={handlePause} style={{ color: '#ffffff' }}>
            <PauseIcon />
          </Button>
          )}
      </Grid>
      <Grid item xs={7} style={{ backgroundColor: '#000000' }}>
        <Slider
          min={dataRange.min}
          max={dataRange.max}
          step={dataRange.step}
          value={-timeOffset}
          onChange={handleSliderChange}
          aria-labelledby="continuous-slider"
        />
      </Grid>
      <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#000000' }}>
        <div
            onClick={onClickLive}
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
        >
          <span style={{ color: liveDotColor, marginRight: '4px' }}>●</span>
          <span style={{ color: '#ffffff' }}>LIVE</span>
        </div>
        {timeOffset === dataRange.max && <span style={{ color: '#ffffff', marginLeft: '4px'  }}> {"["}{-timeOffset}{"]"}</span>}
      </Grid>     
    </Grid>
  );
}

